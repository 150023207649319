import React from 'react'
import { Carousel, Container, Card, Col, Row } from 'react-bootstrap'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { MappedImage } from '../Image'

export const CardTestimonialCarousel = ({ heading, subModules }) => {
  return (
    <Container fluid className="outer">
      <div className="module-heading-wrapper w100 py-4 text-center">
        <h2 className="module-heading">{heading}</h2>
      </div>
      <div className="carousel-wrapper mx-auto" style={{ maxWidth: '960px' }}>
        <Carousel interval={null} variant={'dark'} indicators={false}>
          {subModules &&
            subModules.map(item => (
              <Carousel.Item key={item.heading} className="">
                <Card
                  className="border-0 rounded-0"
                  style={{ backgroundColor: '#F4F4F4' }}
                >
                  <Card.Body>
                    <Row className="">
                      <Col sm={12} md={6}>
                        <div className="">
                          {item.image && (
                            <MappedImage
                              options={{ className: 'w-100', rounded: 'true' }}
                              image={item.image}
                            />
                          )}
                        </div>
                      </Col>
                      <Col sm={12} md={6} className="justify-content-around">
                        <div className="" style={{ fontSize: '1.6rem' }}>
                          {item.heading}
                        </div>
                        <div
                          className="row my-4"
                          style={{
                            fontSize: '1rem',
                            textTransform: 'uppercase',
                          }}
                        >
                          <div className="w-100 row">
                            <div className="col-4">
                              {item.logo && (
                                <MappedImage
                                  options={{ className: 'w-75' }}
                                  image={item.logo}
                                />
                              )}
                            </div>
                            <div className="col-8">
                              <strong>{item.richText}</strong>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Carousel.Item>
            ))}
        </Carousel>
      </div>
    </Container>
  )
}

export const mapCardTestimonialCarouselProps = props => {
  props.subModules &&
    props.subModules.forEach(item => {
      if (item.image) {
        item.imageUrl = item.image.file.url
      }
      if (item.logo) {
        item.logoUrl = item.logo.file.url
      }
      if (item.richText && item.richText.raw) {
        item.richText = documentToReactComponents(JSON.parse(item.richText.raw))
      }
    })

  return props
}
